@import 'src/styles/tokens';

.form-interval .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: 340px;
  display: flex;
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: lighten($color-primary-00, 40%) !important;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: $color-primary-00 !important;
}

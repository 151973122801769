@import 'variables';

@mixin scrollbar-thumb($color: $app-primary) {
  &::-webkit-scrollbar-thumb {
    background: rgba($color, 0.3);

    &:hover {
      background: rgba($color, 0.5);
    }
  }
}

@mixin horizontal-margin-fill($value) {
  width: calc(100% - ($value * 2));
  margin: 0 $value;
}

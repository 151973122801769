@import 'src/styles/tokens';

.general-instructions {
  .tox-tinymce {
    border: 1px solid $color-neutral-20 !important;
    border-radius: $size-8 !important;
  }

  .tox {
    .tox-toolbar__primary {
      background-image: none !important;
    }

    @mixin hoverBackground { background: rgba($color-primary-00, 15%); }
    @mixin hoverColor { color: $color-primary-00 !important; }
    @mixin hoverFill { fill: $color-primary-00 !important; }

    .tox-split-button:hover {
      box-shadow: 0 0 0 1px $color-primary-00 inset !important;
    }

    .tox-tbtn {
      &:not(.tox-tbtn--select) {
        width: $size-32 !important;
        height: $size-32 !important;
      }

      &:not(.tox-tbtn--disabled) {
        cursor: pointer;

        &:hover,
        &.tox-tbtn--active {
          @include hoverBackground;

          .tox-tbtn__select-label {
            @include hoverColor;
          }

          svg {
            @include hoverFill;
          }
        }
      }
    }

    .tox-collection--list .tox-collection__item--active {
      &:not(.tox-collection__item--state-disabled) {
        @include hoverBackground;
        @include hoverColor;

        .tox-collection__item-caret svg {
          @include hoverFill;
        }
      }
    }

    &:not(.tox-tinymce-inline) .tox-editor-header {
      box-shadow: none !important;
      position: relative;

      &:after {
        content: '';
        background: $color-neutral-20;
        height: 1px;
        position: absolute;
        top: 100%;
        left: 16px;
        right: 16px;
      }
    }
  }

  .mat-checkbox {
    color: grey;
    font-size: 14px;

    .mat-checkbox-inner-container {
      margin-top: 4px;
      margin-right: 12px;
    }
  }

  .mat-checkbox-checked {
    .mat-ripple-element,
    .mat-checkbox-background {
      background: #9900ce !important;
    }
  }

  .mat-ripple-element {
    top: 0 !important;
    left: 0 !important;
  }

  .mat-raised-button {
    font-size: 16px !important;
    font-weight: normal !important;
    color: white !important;
    background: #9900ce !important;
    border-radius: 32px !important;
    height: 50px;
    min-height: 50px;
    margin: 0 auto !important;

    &.mat-button-disabled {
      background: rgba(0, 0, 0, 0.12) !important;
    }
  }
}

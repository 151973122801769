// Custom Theming for Angular Material
@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';
// For more information: https://material.angular.io/guide/theming
@import 'styles/closecare-theme';
@import 'styles/palette';
@import 'styles/variables';
// Plus imports for other components in your app.
@import 'font-comfortaa.css';
@import 'font-roboto.css';

// Bootstrap helper css
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";


$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto'
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$custom-background-color: #fff;

// Create the theme object (a Sass map containing all of the palettes).
$closecare-angular-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent:  $app-accent,
    warn: $app-warn
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

$background: map-get($closecare-angular-theme, background);
$background: map_merge($background, (background: $custom-background-color));

$closecare-angular-theme: map_merge($closecare-angular-theme, (background: $background));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($closecare-angular-theme);
@include closecare-theme($closecare-angular-theme);

html, body { }
body { margin: 0; }

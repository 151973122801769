//Colors
$color-primary-00: #1308FE;
$color-primary-10: #1107E5;
$color-primary-20: #0F06CB;
$color-primary-40: #0B0598;
$color-primary-60: #080366;
$color-primary-80: #040233;

$color-secondary-00: #FA8700;
$color-secondary-10: #E17A00;
$color-secondary-20: #C86C00;
$color-secondary-40: #965100;
$color-secondary-60: #643600;
$color-secondary-80: #321B00;

$color-neutral-00: #FFFFFF;
$color-neutral-05: #F2F2F2;
$color-neutral-10: #E8E9E9;
$color-neutral-20: #D1D2D3;
$color-neutral-40: #A2A5A7;
$color-neutral-60: #74797C;
$color-neutral-80: #454C50;
$color-neutral-100: #171F24;

$color-success-light: #AAD3B3;
$color-success-medium: #55A767;
$color-success-dark: #007B1B;

$color-error-light: #F2AAAA;
$color-error-medium: #E55555;
$color-error-dark: #D80000;

$color-hover: #F8F8FF;

$color-shadow-soft: 2, 0, 2, 15%;
$color-shadow-medium: 8, 0, 4, 15%;
$color-shadow-strong: 16, 0, 8, 20%;

// Font Sizes
$font-size-48: 48px;
$font-size-40: 40px;
$font-size-32: 32px;
$font-size-24: 24px;
$font-size-22: 22px;
$font-size-20: 20px;
$font-size-18: 18px;
$font-size-16: 16px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-12: 12px;
$font-size-10: 10px;
$font-size-0: 0px;

// Weights
$font-weight-900: 900;
$font-weight-700: 700;
$font-weight-500: 500;
$font-weight-400: 400;

// Shadows
$box-shadow-soft: 0px 2px 2px rgba(0, 0, 0, 0.15);
$box-shadow-medium: 0px 4px 8px rgba(0, 0, 0, 0.15);
$box-shadow-strong: 0px 8px 16px rgba(0, 0, 0, 0.2);

// Gradients
$gradient-brand: linear-gradient(155.87deg, #9025FC 1.61%, #1108FE 84.53%);

// Sizes
$size-4: 4px;
$size-8: 8px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-24: 24px;
$size-32: 32px;

// Times
$time-100: 100ms;
$time-200: 200ms;
$time-400: 400ms;
$time-600: 600ms;
$time-800: 800ms;

.alert-date {
  .mat-dialog-container {
    background: transparent;
    overflow: hidden;
    box-shadow: none;
    border-radius: 4px !important;
    padding: 0;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.form-exam__autocomplete .mat-option {
  font-size: $font-size-14;
  height: 35px;
  margin: 5px 0;

  &.mat-active,
  &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
  &:hover:not(.mat-option-disabled),
  &:focus:not(.mat-option-disabled) {
    background: rgba(#1308FE, 0.03);
  }
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

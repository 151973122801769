.dialog-save {
  position: relative !important;

  .mat-dialog-container {
    padding: 24px 24px 0 24px;
    display: flex;

    > * {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

app-dialog-save {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@import 'src/styles/tokens';

.form-month-picker {
  &.picker--opened.mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
      opacity: 1;
    }

    &:not(.mat-form-field-invalid.mat-form-field-invalid) .mat-form-field-outline-thick {
      color: $color-primary-00;
    }
  }

  .mat-form-field-infix {
    display: flex;
  }

  .mat-datepicker-toggle {
    margin: -12px -8px;
  }

  .mat-datepicker-toggle-active {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-datepicker-toggle-default-icon {
    width: 22px;
  }
}

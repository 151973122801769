.file-comparison-info {
  .mat-button-toggle {
    font-size: 13px;
    font-weight: 500;
  }

  .mat-button-toggle-group {
    height: 26px;
  }

  .mat-button-toggle-label-content {
    line-height: 26px !important;
  }
}

app-container-header {
  height: 100%;

  > div {
    background: white;
  }

  [content] {
    height: 100%;
  }
}

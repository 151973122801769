.qr-code-container {
  .mat-dialog-title {
    margin: 0;
  }

  .mat-dialog-container {
    padding: 0;
  }

  .mat-form-field {
    font-size: 14px;
    width: 260px;

    & .mat-form-field-flex {
      height: 42px;
    }

    & .mat-form-field-infix {
      position: relative;
      top: -6px;
    }

    & .mat-input-element {
      height: 20px;
      margin-top: -6px;
    }
  }

  .mat-button {
    padding: 0;
    min-width: 0;
    margin-top: 3px;
  }
}

app-companies-list {
  .mat-list-item, .mat-list-item-content {
    flex: 1;
    padding: 8px 0 !important;
  }
  .logged-in {
    button {
      padding: 0;
    }
  }
  .logged-out {
    button {
      border-radius: 8px;
      height: 40px;
      line-height: 40px;
    }
  }
}

@use '@angular/material' as mat;
@import 'palette';

// Create your Sass color vars (will be available in all the project)
$app-primary: mat.define-palette($closecare-blue);
$app-primary-light: mat.define-palette($closecare-blue, 50);
$app-primary-dark: mat.define-palette($closecare-blue, 900);
$app-accent: mat.define-palette($closecare-pink);
$app-accent-light: mat.define-palette($closecare-pink, 300);
$app-warn: mat.define-palette(mat.$red-palette, A700);
$app-third: mat.define-palette($closecare-orange);

$primary: mat.get-color-from-palette($app-primary);
$primary-light: mat.get-color-from-palette($app-primary-light);
$primary-dark: mat.get-color-from-palette($app-primary-dark);
$primary-transparent: mat.get-color-from-palette($app-primary, 0.16);
$accent: mat.get-color-from-palette($app-accent);
$accent-light: mat.get-color-from-palette($app-accent-light);
$warn: mat.get-color-from-palette($app-warn);
$warn-light: mat.get-color-from-palette($app-warn, default, 0.3);
$third-color: mat.get-color-from-palette($app-third);
$third-light: mat.get-color-from-palette($app-third, 500, 0.8);

$app-green: #007B1B;
$app-grey: #525A60;
$app-light-grey: #AAB2B7;
$app-grey-bg: rgba(170, 178, 183, 0.9);
$app-soft-grey: #F2F4F5;
$app-marking-grey: #D7DDE1;
$app-divider-color: rgba(0, 0, 0, 0.12);
$app-text-color: #171F24;
$app-soft-grey: #F2F4F5;
$app-active: #1308fe;
$app-label: #74797C;
$color-link-blue: #03318C;

@import 'src/styles/tokens';

.sidebar-submenu {
  overflow: initial !important;

  .mat-drawer-inner-container {
    overflow: initial;
  }

  &__content {
    transition: $time-400 !important;
  }

  &__content--shrinkage {
    margin-left: 20px !important;
  }
}

.notifications-menu {
  .mat-drawer-backdrop {
    background: transparent !important;
  }
}

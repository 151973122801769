@import 'src/styles/tokens';

.form-multi-select {
  &__field {
    .mat-form-field-wrapper {
      margin: 0 !important;
    }

    .mat-form-field-outline-start {
      border-radius: $size-8 0 0 $size-8 !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 $size-8 $size-8 0 !important;
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 0;
    }
  }

  &__spinner circle {
    stroke: lighten($app-grey, 10%) !important;
  }

  &__autocomplete {
    .mat-option {
      font-size: $font-size-14;
      height: 35px;
      margin: 5px 0;

      &.mat-active,
      &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
      &:hover:not(.mat-option-disabled),
      &:focus:not(.mat-option-disabled) {
        background: rgba(#1308FE, 0.03);
      }

      &--bolder {
        font-weight: bolder;
      }

      &--child {
        padding: 0 $size-32;
      }
    }
  }
}

@import 'src/styles/tokens';

.form-single-select {
  &__spinner circle {
    stroke: lighten($app-grey, 10%) !important;
  }

  &__scroll {
    &--main {
      width: 100%;
    }
    &--description {
      font-size: 13px;
      line-height: 18px;
      color: #666;
      border-left: 1px solid #D7DDE1;
    }
  }

  &__splited .form-single-select__scroll {
    &--main {
      width: calc(60% - 4px);
    }
    &--description {
      overflow: hidden;
      width: 40%;
      height: 100%;
      box-sizing: border-box;
      position: absolute;
      left: 60%;
    }
  }
}

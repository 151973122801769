@import "src/styles/variables";

.card-default {
  mat-card-header {
    .mat-card-header-text {
      margin: 0;
    }
  }

  mat-card-actions {
    padding: 0;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__action {
    color: $primary;
    font-size: 12px;
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

@import './exams/exams.global.scss';
@import './interval/interval.global.scss';
@import './month-picker/month-picker.global.scss';
@import './multi-select/multi-select.global.scss';
@import './single-select/single-select.global.scss';

.small-form-field {
  font-size: 14px;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      margin-top: 0;
    }
    .mat-form-field-label {
      top: 1.84375em;
      margin-top: -0.5em;
    }

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-1.39375em) scale(0.75);
    }

    .mat-form-field-infix {
      padding: 0.5em 0 1em 0;
    }

    .mat-form-field-suffix {
      top: 0.35em;

      .mat-datepicker-toggle {
        margin-right: -0.5em;
      }
    }

    .mat-form-field-outline-start {
      border-radius: 8px 0 0 8px !important;
    }

    .mat-form-field-outline-end {
      border-radius: 0 8px 8px 0 !important;
    }

    .mat-form-field-wrapper {
      margin: 0 !important;
    }

    ::-webkit-calendar-picker-indicator {
      transform: scale(1.75, 1.75);
    }
  }

  .mat-select-arrow {
    margin-top: 8px;
  }

  .mat-form-field-subscript-wrapper {
    position: static;
  }

  .explanation {
    color: $app-grey;
  }
}
